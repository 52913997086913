import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import { Message } from "./models/Message";
import { Conversation } from "./models/Conversation";
import { v4 as uuidv4 } from "uuid";
import { sendMessage } from "./functions/apiService";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';

const App: React.FC = () => {
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [input, setInput] = useState<string>("");
  const [isBotThinking, setIsBotThinking] = useState<boolean>(false);

  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Inicializa a primeira mensagem
    const initialConversation: Conversation = {
      id: uuidv4(),
      messages: [{ text: "Oi!", sender: "bot" }],
    };
    setConversation(initialConversation);
  }, []);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation?.messages.length]);

  const handleSend = async () => {
    if (input.trim() && conversation !== null) {
      const updatedConversation = {
        ...conversation,
        messages: [
          ...conversation.messages,
          { text: input, sender: "user" as const },
        ] as Message[],
      };
      setConversation(updatedConversation);
      setInput("");

      setIsBotThinking(true); // Inicia o "pensamento" do bot

      try {
        const botResponse = await sendMessage(conversation.id, {
          text: input,
          sender: "user" as const,
        });

        // Garante que o texto esteja totalmente carregado antes de atualizar
        const finalBotResponse = botResponse || 'Erro ao carregar a mensagem';

        const updatedConversationWithBotReply = {
          ...updatedConversation,
          messages: [
            ...updatedConversation.messages,
            {
              text: finalBotResponse,
              sender: "bot" as const,
            },
          ] as Message[],
        };
        setConversation(updatedConversationWithBotReply);
      } catch (error) {
        console.error("Erro ao enviar a mensagem:", error);
      } finally {
        setIsBotThinking(false); // Termina o "pensamento" do bot
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="App">
      {/* Bordas fixas */}
      <div className="top-border"></div>
      <div className="left-border"></div>
      <div className="right-border"></div>

      <div className="header-container">
        <div className="header-logo">
          <img src="https://images-rita.s3.amazonaws.com/Group+3.png" alt="Logo RITA" />
          <div className="header-titles">
            <p>
            Olá, eu sou a RITA, Robô Interativa da Tabata Amaral. Vamos conversar? Estou aqui para ouvir suas críticas e sugestões para nosso plano de governo. 
            </p>
          </div>
        </div>
      </div>

      <div className="chat-container">
        <div className="chat-window">
          <div className="chat-messages">
            {conversation?.messages.map((message, index) => (
              <div key={index} className={`chat-bubble ${message.sender}`}>
                {message.sender === "bot" && (
                  <>
                    <img src="https://i.imgur.com/F7wipmI.png" alt="Bot" className="bot-icon" />
                    <span>{message.text}</span>
                  </>
                )}
                {message.sender !== "bot" && <span>{message.text}</span>}
              </div>
            ))}
            {isBotThinking && (
              <div className="chat-bubble bot">
                <LoadingSpinner />
              </div>
            )}
            <div ref={chatEndRef} /> {/* Este div atua como o "fim" da lista de mensagens */}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Digite uma mensagem..."
            />
            <button onClick={handleSend}>
              <span className="material-icons">send</span>
            </button>
          </div>
        </div>
      </div>

      {/* Botões adicionados */}
      <div className="button-container">
        <a href="https://whatsapp.com/channel/0029ValOLKOLtOjGIG84AC3P" target="_blank" rel="noopener noreferrer">
          <button className="custom-button">Acesse o canal da Tabata no WhatsApp</button>
        </a>
        <a href="https://wa.me/5511941100040" target="_blank" rel="noopener noreferrer">
          <button className="custom-button">Converse com a gente</button>
        </a>
      </div>

      {/* Footer */}
      <div className="footer">
        <div className="footer-content">
          {/* Footer desktop */}
          <div className="footer-desktop">
            <a href="https://tabataamaral.com.br/" target="_blank" rel="noopener noreferrer">
              <div className="footer-left">
                <p className="footer-link">VOLTAR AO SITE</p>
              </div>
            </a>
            <div className="footer-center">
              <div className="footer-icons">
                <a href="https://www.instagram.com/tabataamaralsp/" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
                <a href="https://twitter.com/tabataamaralsp" target="_blank" rel="noopener noreferrer">
                  <XIcon />
                </a>
                <a href="https://www.facebook.com/tabataamaralSP" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </a>
                <a href="https://www.youtube.com/@TabataAmaralSP" target="_blank" rel="noopener noreferrer">
                  <YouTubeIcon />
                </a>
                <a href="https://www.tiktok.com/@tabataamaralsp" target="_blank" rel="noopener noreferrer">
                  <PlayArrowIcon />
                </a>
              </div>
            </div>
            <div className="footer-right">
              <a href="https://bit.ly/plano-de-governo-tabata" target="_blank" rel="noopener noreferrer">
                <p className="footer-link">LEIA AS NOSSAS PROPOSTAS</p>
              </a>
            </div>
          </div>

          {/* Footer mobile */}
          <div className="footer-mobile">
            <div className="footer-top">
              <a href="https://tabataamaral.com.br/" target="_blank" rel="noopener noreferrer">
                <div className="footer-left">
                  <p className="footer-link">VOLTAR AO SITE</p>
                </div>
              </a>
              <div className="footer-separator"></div>
              <div className="footer-right">
                <a href="https://bit.ly/plano-de-governo-tabata" target="_blank" rel="noopener noreferrer">
                  <p className="footer-link">LEIA AS NOSSAS PROPOSTAS</p>
                </a>
              </div>
            </div>
            <div className="footer-icons">
              <a href="https://www.instagram.com/tabataamaralsp/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
              <a href="https://twitter.com/tabataamaralsp" target="_blank" rel="noopener noreferrer">
                <XIcon />
              </a>
              <a href="https://www.facebook.com/tabataamaralSP" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://www.youtube.com/@TabataAmaralSP" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon />
              </a>
              <a href="https://www.tiktok.com/@tabataamaralsp" target="_blank" rel="noopener noreferrer">
                <PlayArrowIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
