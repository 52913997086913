// src/services/apiService.ts

import { Message } from "../models/Message";
import { Conversation } from "../models/Conversation";

const API_URL = "https://loro-main-langserve-production.up.railway.app"; // Substitua com a URL real da API

// Função para enviar uma mensagem e receber a resposta do bot
export async function sendMessage(
  conversationId: string,
  message: Message,
): Promise<Message> {
  const response = await fetch(`${API_URL}/rita/webhook/invoke`, {
    method: "POST",
    // adds cors headers and content-type
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      input: message.text,
      session_id: conversationId,
    }),
  });

  if (!response.ok) {
    throw new Error("Erro ao enviar a mensagem");
  }

  const data = await response.json();
  return data.output; // Assume que a API retorna a resposta do bot
}
